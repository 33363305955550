export const PERMISSION_NAME = {
  admin: 'Admin',
  ts: 'TS',
  tsqa: 'TS QA',
  cebqa: 'CEB QA',
}

export const USER_LEVEL_LIST_BY_PERMISSION = {
  admin: ['cebqa', 'ts', 'tsqa'],
  ts: ['ts'],
  tsqa: ['tsqa', 'cebqa'],
  cebqa: ['cebqa'],
}

export const PERMISSION_OPTIONS = Object.entries(PERMISSION_NAME).map(([value, label]) => ({ label, value }))
export const USER_LEVEL_OPTIONS = PERMISSION_OPTIONS.slice(1)
