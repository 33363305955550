<template>
  <AntLayoutHeader class="header">
    <slot></slot>
  </AntLayoutHeader>
</template>

<script>
import { Layout as AntLayout } from 'ant-design-vue'

export default {
  name: 'Header',
  components: {
    AntLayoutHeader: AntLayout.Header,
  },
}
</script>

<style scoped>
.footer {
  padding: 0;
}
</style>
